import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/Layout';
import DigestRoll from '../../components/DigestRoll';

import CTA from '../../components/CTA';

import { Hero, Section } from '../../components/bulma';

export default function DigestIndexPage() {
	return (
		<Layout>
			<Hero
				size="medium"
				color="dark"
				title="FinTech Digest"
				// subtitle={excerpt}
				// image={featuredImage}
			/>
			<Section>
				<DigestRoll />
			</Section>
			{/* <CTA /> */}
		</Layout>
	);
}

// const DIGEST_QUERY = graphql`
// 	query Digest {
// 		allWordpressPost(filter: { categories: { elemMatch: { name: { eq: "cardql-digest" } } } }) {
// 			edges {
// 				node {
// 					id
// 					slug
// 					excerpt
// 					content
// 					date
// 					title
// 					categories {
// 						id
// 						name
// 					}
// 				}
// 			}
// 		}
// 	}
// `;
